<template>
  <div class="page">
    <div class="page-sub-title">筛选查询</div>
    <div>
      <div class="search-item">
        <span class="label">操作日期</span>
        <el-date-picker
            v-model="createTime"
            type="date"
            size="small"
            value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="search-item">
        <span class="label">报错接口</span>
         <el-input v-model="url" placeholder="请输入报错接口" size="small" style="width:200px"></el-input>
      </div>
      <div class="search-item">
        <span class="label">requestId</span>
         <el-input v-model="requestId" placeholder="请输入报错接口" size="small" style="width:200px"></el-input>
      </div>
      <div class="search-item">
        <span class="label">报错来源</span>
         <el-select v-model="clientType" placeholder="请选择" size="small" style="width: 160px" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="(item,i) in typeList"
              :key="i"
              :label="item.name"
              :value="item.val">
            </el-option>
          </el-select>
      </div>
      <div class="search-item">
        <el-button type="primary" size="small" @click="search()">查询</el-button>
      </div>
    </div>
    <div class="page-sub-title">报错列表</div>
    <div>
      <el-table
          :data="list"
          style="width: 100%"
          border
          header-row-class-name="table-header"
      >
        <el-table-column align="center" prop="url" label="接口"></el-table-column>
        <el-table-column align="center" prop="method" label="接口类型"></el-table-column>
        <el-table-column align="center" prop="createTime" label="时间"></el-table-column>
        <el-table-column align="center" prop="param" label="入参数据">
          <template slot-scope="scope">
            <span class="cursor-point c-theme" @click="showDialog(0,scope.row)">查看详情</span>
            <!-- {{JSON.parse(scope.row.param).enterData || JSON.parse(scope.row.param) }} -->
          </template>
        </el-table-column>
        <el-table-column align="center" prop="param" label="errorLog">
          <template slot-scope="scope">
            <span class="cursor-point c-theme" @click="showDialog(1,scope.row)">查看详情</span>
            <!-- {{JSON.parse(scope.row.param).enterData || JSON.parse(scope.row.param) }} -->
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="param" label="用户酒店">
          <template slot-scope="scope">
            {{scope.row.param ? JSON.parse(scope.row.param)?.hotelInfo.hotelName : '' }}
          </template>
        </el-table-column> -->
        <el-table-column align="center" prop="param" label="返回信息">
          <template slot-scope="scope">
            {{scope.row.param ? JSON.parse(scope.row.param).errMsg : '' }}
            <div>{{'requestId:'+ scope.row.requestId}}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="来源">
          <template slot-scope="scope">
            {{scope.row.clientType | whichType }}
            <div v-if="scope.row.clientType === 'YANHUIJIA_APP'">
              UA：{{scope.row.param ? JSON.parse(scope.row.param).ua : '' }}
              <div>
                APP版本：{{scope.row.param ? JSON.parse(scope.row.param).version : '' }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          class="text-right m-t-16"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[5,10,15,20]"
          :current-page="pageParams.pageNo"
          :page-size="pageParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems">
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogFlag"
      v-if="dialogFlag"
      width="600px"
      title="日志信息"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="addDepartment"
      append-to-body
      :before-close="hideDialog">
      <div class="dialog-body content">
        <!-- <span v-if="detail">{{JSON.parse(detail.param).enterData || JSON.parse(detail.param)}}</span> -->
        <span v-if="detail">{{detail}}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {get_errorLog} from "@/api/log";
import { pageMixin } from '@/mixins/pageMixin'

export default {
  name: "",
  mixins: [pageMixin],
  filters: {
    whichType(val) {
      let arr = {
        'HOTEL_WEB': "pc",
        'YANHUIJIA_APP': "app",
        'DINGYI_MANAGER': "运维",
        'MINIPROGRAM': "小程序",
      };
      return arr[val];
    }
  },
  data() {
    return {
      list: [],
      totalItems: 0,
      createTime: '',
      url: '',
      typeList: [
        {
          name: 'pc',
          val: 'HOTEL_WEB'
        },
        {
          name: 'app',
          val: 'YANHUIJIA_APP'
        },
        {
          name: '运维',
          val: 'DINGYI_MANAGER'
        },
        {
          name: '小程序',
          val: 'MINIPROGRAM'
        },
      ],
      clientType: '',
      requestId: '',
      dialogFlag: false,
      detail: ''
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      get_errorLog({
        createTime: this.createTime,
        url: this.url,
        clientType: this.clientType,
        requestId: this.requestId,
        ...this.pageParams
      }).then(res => {
        this.list = res.records || []
        this.totalItems = res.total
      })
    },
    showDialog(type,val) {
      this.dialogFlag = true
      if(type == 0) {
        this.detail = JSON.parse(val.param).enterData || JSON.parse(val.param)
      } else {
        this.detail = val.errorLog
      }
      
    },
    hideDialog() {
      this.dialogFlag = false
    }
  },
};
</script>

<style>
.page{
  padding: 12px 24px 12px 24px;
  background-color: white;
}
.c-theme {
  color: #5390FF;
}
</style>
